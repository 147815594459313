
import Repository from '@/core/Services/Repository';
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';

export default class Dashboard extends Repository {
  
  constructor() {
    super(
      new ApiRouter({
        fetchAll: { url: '/v1/dashboard', method: 'GET', headers: {} },
        "monthly": { url: '/data/monthly', method: 'GET', headers: {} },
        "kpis": { url: '/data/kpis', method: 'GET', headers: {} },
        "table": { url: '/data/table', method: 'GET', headers: {} },
        "diversity": { url: '/data/diversity', method: 'GET', headers: {} },
        "aggdiversity": { url: '/data/agg-diversity', method: 'GET', headers: {} },
        "jobs": { url: '/data/jobs', method: 'GET', headers: {} },
      }),
    );
  }

  public async fetchJobsList(data?: any) {
    let r_data: any = null;
    const key: string = 'job_list_simple';
    r_data = this.getLocalData(key);
    if(!r_data) {
        r_data = await this.call('jobs', (!data ? {} : data), 'application/json');
        this.saveLocalData(key,r_data,null)
    }
    return r_data;
  }

  public fetchKpis(data?: any) {
    return this.call('kpis', (!data ? {} : data), 'application/json');
  }
  
  public fetchMonthlyKpis(data?: any) {
    return this.call('monthly', (!data ? {} : data), 'application/json');
  }
  
  public fetchTable(data?: any) {
    return this.call('table', (!data ? {} : data), 'application/json');
  }
  
  public fetchDiversity(data?: any) {
    return this.call('diversity', (!data ? {} : data), 'application/json');
  }
  
  public fetchAggDiversity(data?: any) {
    return this.call('aggdiversity', (!data ? {} : data), 'application/json');
  }


}
