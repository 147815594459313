<template>
  <div class="container-fluid mt-1">
    <!-- Tabela Completa -->
    <b-row no-gutters  >
      
      <b-col md="12">
        <b-card>
          <b-row class="mb-2">
            <b-col md="12">
              <b-form-group 
                :label="$t('Job')" 
                label-for="vagaSelect-report">
                    <multi-select 
                      id="vagaSelect-report"
                      class="searh-job-input"
                      v-model="selectedVaga" 
                      :options="vagaOptions" 
                      :multiple="true"
                      label="text" 
                      track-by="text"
                      :custom-labels="customLabel"
                      :show-labels="false"
                      :placeholder="$t('Select')">
                        <template slot="option" slot-scope="props">
                          <div class="option__desc">{{ $t(props.option.text) }}</div>
                        </template>
                    </multi-select>
              </b-form-group>
            </b-col>
            <b-col md="12">
                <b-form-checkbox v-model="filterByDate" name="check-button" switch class="mb-2">
                  {{$t("Filter by Application Date")}} <b>({{ filterByDate?"On":"Off" }})</b>
                </b-form-checkbox>

                <div class="d-flex justify-content-md-between align-items-center">
                  <div class="mx-3">{{$t('From')}}</div> 

                  <b-form-datepicker
                    :disabled="!filterByDate"
                    id="input-date-pub"
                    class="input -date-pub"
                    v-model="dtIni"
                    value-as-date
                    :locale="$i18n.locale"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                  />

                  <div class="mx-3">{{$t('Until')}}</div> 

                  <b-form-datepicker
                    :disabled="!filterByDate"
                    id="input-date-pub-end"
                    class="input-date-pub-end"
                    v-model="dtFim"
                    value-as-date
                    :locale="$i18n.locale"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                  />
                </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col md="12" class="mb-2 d-flex justify-content-center">
          <div class="btn-animation m-2" style="position:relative"  v-show="items && items.length > 0" >
              <lootie animation="download" :show="true" :autoplay="true" :width="50" :height="50" v-model="lottieDownload"/>
            </div>
        <b-button variant="outline-primary" class="px-5 py-3"  @click="loadReport" v-promise-btn="{promise}">  
              <i class="fa fa-arrow-down"></i>  {{$t('Generate report')}} 
          </b-button>
          <a id="downloadLink" href="javascript:void(0)" ></a>
      </b-col>

      
      <b-col sm="12" >
        <div class="text-left text-primary p-3 mx-3" v-if="isBusy">
          <div class="d-flex justify-content-center">
            <b-spinner class="align-middle mx-2" variant="primary"></b-spinner>
            <strong>{{$t('Loading your report')}}...</strong>
          </div>
        </div>
        <b-alert show variant="warning" class="text-center" v-else-if="items && items.length == 0">
          <strong>{{ $t("No application found") }}</strong>
        </b-alert>
        <div v-show="showTable">
        <transition name="slide-fade" >
          <b-table id="completeRep" striped hover responsive :items="items" :fields="fields"  >
            <template v-slot:head()="data">
              {{ data.label.toUpperCase() }}
            </template>
          </b-table>
        </transition>
        </div>
      </b-col>
    </b-row>
  </div>
</template>


<script lang="ts">
import LootieAnimation from '@/components/loader/LootieAnimation.vue';
import Dashboard from '@/core/Services/Empresa/Dashboard';
import { siteCandidateEndPoint } from '@/core/endpoint';
import Multiselect from 'vue-multiselect';
import { Component, Vue } from 'vue-property-decorator';
import ExportTable from './ExportTable';

@Component<ReportTableComplete>({
  components: {
    'multi-select': Multiselect,
    'lootie': LootieAnimation
  }
})
export default class ReportTableComplete extends Vue {
  public service: Dashboard;
  public isBusy: boolean = false;
  public fields: any [] | null = null;
  public items: any [] | null = null;
  public fieldsSimple: any [] | null = null;
  public itemsSimple: any [] | null = null;
  public activeNav: number = 0;
  public dtIni: Date|null = null;
  public dtFim: Date|null = null;
  public vagaId: string = '';
  public selectedVaga: any = null;
  public vagaOptions: any[] = [];
  public promise: Promise<any>|null = null;
  public filterByDate: boolean = false;
  public timeout: any = 0;
  public lottieDownload: any = null;
  public showTable: boolean = false;

  constructor() {
    super();
    this.service = new Dashboard();
    this.isBusy = false;
  }

  
  public created() {
    this.dtFim = new Date();
    this.dtIni = this.referenceDate(this.dtFim, -30)
  }

  public async mounted() {
    this.activeNav = 0;
    this.loadFields();
    this.loadJobs();
    // await this.loadReport()
  }

  public customLabel({text,value}) {
    return ""+this.$t(text)
  }
  
  public async loadJobs() {
    const data: any[] = await this.service.fetchJobsList();
    this.vagaOptions.push({value: '', text: this.$t('All')})
    data.forEach(vagaOptin => {
      this.vagaOptions.push(vagaOptin)
    });
  }

  public loadFields() {
    this.fields = [     
      { key: 'datapublicacao', label: this.$t('Publication'), class: 'text-left' },
      { key: 'recrutador', label: this.$t('Recruiter'), class: 'text-left' },
      { key: 'posicao', label: this.$t('Position'), class: 'text-left' },
      { key: 'status_vaga', label: this.$t('Job Status'), class: 'text-left' },
      { key: 'datacandidatura', label: this.$t('Application date'), class: 'text-left' },
      { key: 'dataatualiza', label: this.$t('Video Record date'), class: 'text-left' },
      { key: 'idcandidatura', label: 'ID', class: 'text-left' },
      { key: 'nome_candidato', label: this.$t('Candidate'), class: 'text-left' },
      { key: 'email', label: this.$t('E-mail'), class: 'text-left' },
      { key: 'telefone', label: this.$t('Telephone'), class: 'text-left' },
      { key: 'link_perfil', label: this.$t('Profile'), class: 'text-left' },
      { key: 'cpf', label: this.$t('CPF'), class: 'text-left' },
      { key: 'localidade', label: this.$t('Location'), class: 'text-left' },
      { key: 'link_curriculum_pdf', label: this.$t('Curriculum'), class: 'text-left' },
      { key: 'hidden_cv', label: this.$t('Anonymous Resume'), class: 'text-left' },
      { key: 'status_candidatura', label: this.$t('Status'), class: 'text-left' },
      { key: 'score', label: 'Score', class: 'text-left' },
      { key: 'perc_match', label: '% Match', class: 'text-left' },
      { key: 'avaliacao', label: this.$t('Recruiter Evaluation'), class: 'text-left' },
      { key: 'avaliacao_gestor', label: this.$t('Manager Evaluation'), class: 'text-left' },
      { key: 'nota_total', label: this.$t('Candidate note'), class: 'text-left' },
      { key: 'data_encerramento', label: this.$t('Closing'), class: 'text-left' },
      { key: 'diff_em_dias', label: this.$t('Closing time'), class: 'text-left' },
      { key: 'score_avg', label: this.$t('Exam average score'), class: 'text-left' },
      { key: 'genero', label: this.$t('Gender'), class: 'text-left' },
      { key: 'raca_cor', label: this.$t('Race/Ethnicity'), class: 'text-left' },
      { key: 'pcd', label: this.$t('People with disabilities'), class: 'text-left' },
      { key: 'grp_black', label: this.$t('Afro-descendants Community'), class: 'text-left' },
      { key: 'grp_lgbt', label: 'LGBTQIA+', class: 'text-left' },
      { key: 'grp_indigena', label: this.$t('Indigenous'), class: 'text-left' },
      { key: 'grp_refugiado', label: this.$t('Refugee and Immigrants'), class: 'text-left' },
      { key: 'grp_pcd', label: this.$t('PWD Group'), class: 'text-left' },
      { key: 'grp_fem', label: this.$t('Womens Movement'), class: 'text-left' },
      { key: 'grp_3ida', label: '50 +', class: 'text-left' },
      { key: 'last_evaluator_name', label: this.$t('Last Evaluator Name'), class: 'text-left' },
      { key: 'last_evaluator_email', label: this.$t('Last Evaluator Email'), class: 'text-left' },
      { key: 'last_evaluation_body', label: this.$t('Last Eval'), class: 'text-left' },
      { key: 'last_evaluation_status', label: this.$t('Last Eval Status'), class: 'text-left' },
      { key: 'avg_evaluations', label: this.$t('AVG Evaluation Score'), class: 'text-left' },
      { key: 'last_evaluation_date', label: this.$t('Last Eval Date'), class: 'text-left' },
    ];
    this.fieldsSimple = [
      { key: 'posicao', label: this.$t('Position'), class: 'text-left' },
      { key: 'datapublicacao', label: this.$t('Publication Date'), class: 'text-left' },
      { key: 'recrutador', label: this.$t('Recruiter'), class: 'text-left' },
      { key: 'candidaturas', label: this.$t('Applications'), class: 'text-left' },
      { key: 'entrevistas', label: this.$t('Recorded Interviews'), class: 'text-left' },
      { key: 'status_vaga', label: this.$t('Job Status'), class: 'text-left' },
      { key: 'grp_black', label: this.$t('Afro-descendants Community'), class: 'text-left' },
      { key: 'grp_lgbt', label: 'LGBTQIA+', class: 'text-left' },
      { key: 'grp_indigena', label: this.$t('Indigenous'), class: 'text-left' },
      { key: 'grp_refugiado', label: this.$t('Refugee and Immigrants'), class: 'text-left' },
      { key: 'grp_pcd', label: this.$t('PWD Group'), class: 'text-left' },
      { key: 'grp_fem', label: this.$t('Womens Movement'), class: 'text-left' },
      { key: 'pcd', label: this.$t('People with disabilities'), class: 'text-left' },
      { key: 'masculino', label: this.$t('Man'), class: 'text-left' },
      { key: 'feminino', label: this.$t('Woman'), class: 'text-left' },
      { key: 'h_trans', label: this.$t('Man Trans'), class: 'text-left' },
      { key: 'm_trans', label: this.$t('Woman Trans'), class: 'text-left' },
      { key: 'n_binario', label: this.$t('Non-binary'), class: 'text-left' },
      { key: 'outros', label: this.$t('Others'), class: 'text-left' }
    ];
  }

  public getDateString(v: Date) {
    try{
      const day = (v.getUTCDate()).toString();
      const month = (v.getUTCMonth() + 1).toString();
      const year = v.getUTCFullYear();
      return (day.length == 1 ? "0" + day : day) + "/" + (month.length == 1 ? "0" + month : month) + "/" + year;
    } catch(e) {
      return null;
    }
  }

  public referenceDate(date: any, number: number) {
    let data: Date|null = null;
    try {
        if ((typeof date === "string")) { // dd/mm/yyyy
            data = new Date(parseInt(date.substring(6, 10)), parseInt(date.substring(3, 5)) - 1, parseInt(date.substring(0, 2)))
        } else {
            data = new Date(date);
        }

        const dataAux = new Date(data!);
        dataAux.setDate(data!.getDate() + (number));

        return dataAux;
    } catch (e) {
        console.error(e);
        return null;
    }
  }

  public downloadReport() {
    try {
      console.log('start aqui')
      const table: any = document.querySelector('#'+ (this.activeNav==0?'completeRep':'simpleRep'));
      const fName: string = 'jobecam_report_'+(new Date().getTime())+'.csv';
      const x = new ExportTable(table,fName,document.querySelector('#downloadLink'))
      console.log('end aqui')
      
      this.lottieDownload.stop();
      this.lottieDownload.play();
      (document.querySelector('#downloadLink') as HTMLLinkElement)!.click();
    } catch (e) {
      console.error(e)
    }
  }
  public decodeHtmlEntities(str) {
    // Create a temporary DOM element to leverage browser decoding
    const tempElement = document.createElement('div');
    tempElement.innerHTML = str;

    // Use textContent to get the plain text with decoded entities
    return tempElement.textContent || tempElement.innerText || "";
  }

  public stripHtmlAndDecode(str) {
    // Strip HTML tags and decode HTML entities
    const strippedString = str.replace(/<\/?[^>]+(>|$)/g, ""); // Removes HTML tags
    return this.decodeHtmlEntities(strippedString);
  }
  
  public async loadReport() {
    this.promise = new Promise(async (resolve,reject) => {
      try {
        this.items = []
        this.isBusy = true;
        const filter = {report: 'table'}
        const vuid = this.$route.params.vguid;

        if(this.filterByDate) {
          if (this.dtIni) {
            filter['fts'] = `datap_i=${this.getDateString(this.dtIni)}`
          }
          if (this.dtFim) {
            filter['fts'] += (!!filter['fts'] ? ';' : '') + `datap_f=${this.getDateString(this.dtFim)}`
          }
        }
        if(this.selectedVaga instanceof Array) {
          console.log(this.selectedVaga, 'selectedvagas')
          let guids: string[] = [];
          for (let index = 0; index < this.selectedVaga.length; index++) {
            const element: {text: string, value: string} = this.selectedVaga[index];
            if(element.value)
              guids.push(element.value);
          }
            filter['vguids'] = guids.join(',');
          
        } else if(this.selectedVaga?.value) {
          this.vagaId = this.selectedVaga?.value
        }
        if (this.vagaId) {
          filter['vuid'] = this.vagaId;
        }
        
        const data: any = await this.service.fetchTable(filter).catch(reject);
        this.items = [];
        this.itemsSimple = [];
        
        let dtIn: any = {};
        let dtInSimple: any = {};
        const arrayBinFields:string[] = ['grp_black','grp_lgbt','grp_indigena','grp_refugiado','grp_pcd','pcd','grp_fem','grp_3ida'];

        data['report'].forEach((element: any ) => {
          
          this.fields!.forEach((elKey: any) => {
            dtIn[elKey.key] = !element[elKey.key] ? ' - ' : (element[elKey.key]+'').replace('{CANDIDATE_LINK}', siteCandidateEndPoint);
            if (element[elKey.key] == 'Sim')
              dtIn[elKey.key] = this.$t("Yes")  
            if (element[elKey.key] == 'Não')
              dtIn[elKey.key] = this.$t("No")
            if (element[elKey.key] == 'Não informado') 
              dtIn[elKey.key] = this.$t("Not Informed")
            
            if(elKey.key == 'dataatualiza' && !(element['status_candidatura'] == 'APROVADO' || element['status_candidatura'] == 'EM ANALISE' || element['status_candidatura'] == 'REPROVADO')){
              dtIn[elKey.key] = ""
            }
            
            if (elKey.key == 'avg_evaluations' && element[elKey.key]){
              dtIn[elKey.key] = parseFloat(element[elKey.key])||""
            }

            if (elKey.key == 'last_evaluation_body' && element[elKey.key]){
              dtIn[elKey.key] = this.stripHtmlAndDecode(element[elKey.key])
            }
            
            if (elKey.key == 'last_evaluation_status' && element[elKey.key]){
              dtIn[elKey.key] = this.$t(this.firstLetterCapitilize(element[elKey.key]?.toLocaleLowerCase())).toString()
            }

            if (elKey.key == 'genero') {
              dtIn[elKey.key] = this.$t(this.firstLetterCapitilize(element[elKey.key]?.toLocaleLowerCase())).toString()
            }
          });
          dtIn['hidden_cv'] = location.protocol + "//" + location.host + '/t/info/'+element.cguid
          this.items!.push(dtIn);
          dtIn = {};
          if (!dtInSimple[element.id]) {
              dtInSimple[element.id] = {
                posicao: element.posicao,
                datapublicacao: element.datapublicacao,
                recrutador: element.recrutador,
                candidaturas: 0,
                entrevistas: 0,
                status_vaga: element.status_vaga,
                masculino: 0,
                feminino: 0,
                h_trans: 0,
                m_trans: 0,
                n_binario: 0,
                outros: 0,
                grp_pcd: 0,
                grp_black: 0,
                grp_fem: 0,
                grp_refugiado: 0,
                grp_indigena: 0,
                grp_lgbt: 0,
                grp_3ida: 0,
                ni: 0

              }
            } 
            dtInSimple[element.id].candidaturas += 1;
            dtInSimple[element.id].entrevistas += parseInt(element.n_respostas || "0");
            //diversidade grupos
            dtInSimple[element.id].grp_pcd += ((element.grp_pcd == 'Sim') ? 1 : 0);
            dtInSimple[element.id].grp_black += ((element.grp_black == 'Sim') ? 1 : 0);
            dtInSimple[element.id].grp_fem += ((element.grp_fem == 'Sim') ? 1 : 0);
            dtInSimple[element.id].grp_refugiado += ((element.grp_refugiado == 'Sim') ? 1 : 0);
            dtInSimple[element.id].grp_indigena += ((element.grp_indigena == 'Sim') ? 1 : 0);
            dtInSimple[element.id].grp_lgbt += ((element.grp_lgbt == 'Sim') ? 1 : 0);
            dtInSimple[element.id].grp_3ida += ((element.grp_3ida == 'Sim') ? 1 : 0);

            //diversidade genero        m('MASCULINO','FEMININO','MULHER TRANS','HOMEM TRANS','NÃO-BINÁRIO','OUTROS') 
            dtInSimple[element.id].masculino += ((element.genero == 'MASCULINO') ? 1 : 0)
            dtInSimple[element.id].feminino += ((element.genero == 'FEMININO') ? 1 : 0)
            dtInSimple[element.id].m_trans += ((element.genero == 'MULHER TRANS') ? 1 : 0)
            dtInSimple[element.id].h_trans += ((element.genero == 'HOMEM TRANS') ? 1 : 0)
            dtInSimple[element.id].n_binario += ((element.genero == 'NÃO-BINÁRIO') ? 1 : 0)
            dtInSimple[element.id].outros += ((element.genero == 'OUTROS') ? 1 : 0)
            dtInSimple[element.id].ni += ((element.genero == 'Não informado') ? 1 : 0)
          
        });
        let ks: string[]  = Object.keys(dtInSimple)
        ks.forEach((_key: string) => {
          this.itemsSimple!.push(dtInSimple[_key])      
        });
        setTimeout(() => {
          this.downloadReport()
        },2000)
        resolve(true);
      } catch (error) {
        reject();
      } finally {
        this.isBusy = false;
      }
    })
  }

  public firstLetterCapitilize(str: string) {
    if(!str)
      return '';
      
    const arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    return arr.join(" ");
  }
  
  public setActiveNav(nav: number) {
      this.activeNav = nav;
  }
  

}

</script>


<style lang="scss">

</style>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

$card-qt-border-color: #f1f1f1;
.tabs {
.nav {
    .nav-item {
      border-bottom: 1px solid $border-nav-blue;
      cursor: pointer;
      a {
        color: $border-nav-blue;
      }
      &.active {
        border-bottom: 1px solid $blue;
        a {
          color: $blue;
        }
      }
    }
  }
  .card-qt {
    border: 0.13em solid $card-qt-border-color;
    border-radius: 1.25em;
    min-height: 250px;

    &__header {
      font-size: 12px;
      padding: 5px 20px;
      margin-top: 1.25em;
      color: $gray;
    }
    &__body {
      &__row {
        width: 100%;
        max-height: 3000px;
        overflow-x: auto;
        font-size: 12px;
      }
      &__content {
        border-left: 0.1em solid $card-qt-border-color;
        width: 100%;
        text-align: center;

        &:first-child {
          border-left: none;
        }

        &__qt {
          font-size: 2.7em;
        }
      }
    }
  }
.latest-candidaturas {
  &__image {
    img {
      width: 2.5em;
      border-radius: 50%;
    }
  }
  &__names {
    line-height: 1.2em;
  }
}
}
</style>